const loadSvgSprite = (url) => {
    fetch(url)
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.text();
        })
        .then(svgContent => {
            const div = document.createElement('div');
            div.style.display = 'none';
            div.innerHTML = svgContent;
            document.body.insertBefore(div, document.body.firstChild);
        })
        .catch(error => {
            console.error('Error loading the SVG sprite:', error);
        });
}

export default loadSvgSprite

document.addEventListener("DOMContentLoaded", function () {
    loadSvgSprite('/theme/sprites.svg');
});