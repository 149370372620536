// Create our stylesheet
export default (path = '/node_modules/bootstrap-italia/dist/fonts') => {
  if (typeof document === 'undefined') {
    return
  }
  const styleNode = document.createElement('style')
  //const __PUBLIC_PATH__ = window.__PUBLIC_PATH__ ? window.__PUBLIC_PATH__ : path
  styleNode.innerHTML = `
  @font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: bold;
    font-display: swap;
    src: url('https://cdn.portali.csi.it/font/publicsans/v1/webfont/publicsans-bold.ttf');
  }

  @font-face {
    font-family: 'Public Sans';
    font-style: italic;
    font-weight: bold;
    font-display: swap;
    src: url('https://cdn.portali.csi.it/font/publicsans/v1/webfont/publicsans-bolditalic.ttf');
  }

  @font-face {
    font-family: 'Public Sans';
    font-style: italic;
    font-weight: normal;
    font-display: swap;
    src: url('https://cdn.portali.csi.it/font/publicsans/v1/webfont/publicsans-italic.ttf');
  }

  @font-face {
    font-family: 'Public Sans';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: url('https://cdn.portali.csi.it/font/publicsans/v1/webfont/publicsans-regular.ttf');
  }

  @font-face {
    font-family: 'PT Serif';
    font-style: italic;
    font-weight: bold;
    font-display: swap;
    src: url('https://cdn.portali.csi.it/font/ptserif/v1/webfont/ptserif-bolditalic-webfont.ttf');
  }

  @font-face {
    font-family: 'PT Serif';
    font-style: normal;
    font-weight: normal;
    font-display: swap;
    src: url('https://cdn.portali.csi.it/font/ptserif/v1/webfont/ptserif-regular-webfont.ttf');
  }

  @font-face {
    font-family: 'PT Serif';
    font-style: normal;
    font-weight: bold;
    font-display: swap;
    src: url('https://cdn.portali.csi.it/font/ptserif/v1/webfont/ptserif-bold-webfont.ttf');
  }

  @font-face {
    font-family: 'PT Serif';
    font-style: italic;
    font-weight: normal;
    font-display: swap;
    src: url('https://cdn.portali.csi.it/font/ptserif/v1/webfont/ptserif-italic-webfont.ttf');
  }
  `
  document.getElementsByTagName('head')[0].appendChild(styleNode)
}
